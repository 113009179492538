import React from 'react'
import styled from 'styled-components'
import Carousel from '../Carousel'
import Button from '../Button'
import { ThemeProvider } from 'styled-components'
import {dark} from '../../styles/Themes'

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 75%;
`

const Box = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 50%;
`
const Title = styled.h2`
  align-self: flex-start;
  color: ${props => props.theme.body};
  font-size: ${props => props.theme.fontxxl};
  text-transform: capitalize;
  width: 80%;
  margin: 0 auto;
`

const SubText = styled.p`
  align-self: flex-start;
  color: ${props => props.theme.body};
  font-size: ${props => props.theme.fontlg};
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;
`
const SubTextLight = styled.p`
  align-self: flex-start;
  color: ${props => `rgba(${props.theme.bodyRgba}, 0.6)`};
  font-size: ${props => props.theme.fontmd};
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;
`

const ButtonContainer = styled.div`
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
`

const About = () => {
  return (
    <Section id='about'>
      <Container>
        <Box><Carousel /></Box>
        <Box>
          <Title>
            Welcome To The PxLabs Club.
          </Title>
          <SubText>
          PxLabs is working on bringing an easy to use ecosystem for new artists in the NFT space! We are also focusing on building a crypto casino as part of the utility for membership pass holders!
          </SubText>
          <SubTextLight>
            We're helping many aspiring young artist to launch their collections on various different blockchains. Join an ambitious ever-growing community with multiple benefits and utilities.
          </SubTextLight>
          <ButtonContainer>
            <ThemeProvider theme={dark} >
              <Button onClick={() => window.open('https://discord.gg/pxlabs/')} text="Join our Discord!" link="#about" />
            </ThemeProvider >
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  )
}

export default About
